import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUser } from "../../Common/css/api-instans/api-instan";
import { useSelector } from "react-redux";
const Footer = () => {
  const [logo, setLogo] = useState({});
  const [error, setError] = useState({});
  const [socialicon, setSocialicon] = useState([]);
  const [footer, setFooter] = useState(null);
  const [link, setLink] = useState([]);
  const token = useSelector((state) => state?.token);
  const sliderFatchData = async () => {
    try {
      const userData = await getUser(
        "websitecontentsql/get_dynamicimage",
        {
          sectiontype: "Logo",
        },
        token
      );
      setLogo(userData?.data[0]);
    } catch (error) {
      setError(error);
    }
  };

  const fatchData = async () => {
    try {
      const userData = await getUser(
        "websitecontentsql/get_websitecontent",
        {
          sectiontype: "",
        },
        token
      );

      userData?.data?.map((item) => {
        switch (item.sectiontype) {
          case "socialicon footer":
            setSocialicon((prevData) => {
              if (
                !prevData.find(
                  (dataItem) =>
                    dataItem.websitecontentid === item.websitecontentid
                )
              ) {
                return [...prevData, item];
              } else {
                return prevData;
              }
            });
            break;
          case "link":
            setLink((prevData) => {
              if (
                !prevData.find(
                  (dataItem) =>
                    dataItem.websitecontentid === item.websitecontentid
                )
              ) {
                return [...prevData, item];
              } else {
                return prevData;
              }
            });
            break;
          case "Footer":
            setFooter(item);
            break;

          default:
            break;
        }
      });
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    sliderFatchData();
    fatchData();
  }, []);

  const [loading, setLoading] = useState(false);
  const BASE_URL = process.env.REACT_APP_API_URL;

  const handleDownloadAPK = async () => {
    setLoading(true);
    try {
      const response = await getUser(
        `${BASE_URL}websitecontentsql/get_apkfile`,
        {}, // Pass empty params as no body is required
        token
      );

      if (response.issuccess) {
        const apkUrl = response.data;
        if (apkUrl) {
          // Trigger the download
          const anchor = document.createElement("a");
          anchor.href = apkUrl;
          anchor.download = "app-release.apk"; // Optional: specify the file name
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        } else {
        }
      } else {
      }
    } catch (error) {
      console.error("Error downloading APK file:", error);
      alert("An error occurred while trying to download the APK file.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="footer-main">
      <div className="container">
        <div className="row g-4 pb-3 border-bottom">
          <div className="col-lg-10">
            <div className="footer-box">
              <Link to="/">
                <img
                  src={logo?.imageurl}
                  className="img-fluid mb-3"
                  width={100}
                />
              </Link>
              <div className="sociel-icon">
                {socialicon?.length > 0 &&
                  socialicon.map((item, index) => (
                    <a
                      href={item?.link}
                      key={index}
                      dangerouslySetInnerHTML={{ __html: item?.socialicon }}
                    ></a>
                  ))}
                {/* <a href="#">
              <i className="fa fa-instagram" aria-hidden="true" />
            </a>
            <a href="#">
              <i className="fa fa-twitter" aria-hidden="true" />
            </a>
            <a href="#">
              <i className="fa fa-linkedin" aria-hidden="true" />
            </a> */}
              </div>
              {footer?.description && (
                <div className="footer-links mt-4">
                  <Link
                    to="/termsandconditions"
                    className="me-3 d-inline-block"
                  >
                    Terms &amp; Conditions
                  </Link>
                  <Link to="/privacyplociy" className="me-3 d-inline-block">
                    Privacy
                  </Link>
                  <Link to="/deleteaccount" className="me-3 d-inline-block">
                    Delete Account
                  </Link>
                  <Link
                    to=""
                    onClick={() => handleDownloadAPK()}
                    className="me-3 d-none"
                  >
                    Download Apk
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-2">
            <div className="footer-app-btn">
              <h4 className="mb-3">{footer?.title}</h4>
              {link?.length > 0 &&
                link.map((item, index) => (
                  <a key={index} href={item?.link} className="d-block mb-3">
                    <img src={item?.imageurl} className="img-fluid" />
                  </a>
                ))}
            </div>
          </div>
        </div>
        <div className="copy-right-box pt-3 text-center">
          {footer?.description}
        </div>
      </div>
    </div>
  );
};

export default Footer;
